<template>
  <div class="mt-4">
    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h1 class="page-title">
        <b-icon icon="cpu-fill" />
        {{ $t("nodes.title") }}
      </h1>
    </div>

    <b-card border-variant="light" class="mt-4 shadow-sm card-rounded">
      <b-form @submit.prevent="search">
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              autocomplete="off"
              data-cy="search-input"
              :placeholder="$t('typeToSearch')"
              type="search"
            />

            <b-input-group-append>
              <b-button data-cy="user-clear-search-button" @click="clearFilter">
                <b-icon-x />
              </b-button>

              <b-button
                variant="primary"
                data-cy="user-search-button"
                type="submit"
              >
                {{ $t("search") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-form>
      <b-table
        :busy="loading"
        :current-page="currentPage"
        :fields="fields"
        :head-variant="'light'"
        :items="nodes"
        :per-page="0"
        class="mb-0"
        data-cy="nodes-table"
        fixed
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-items-center mr-2" />
            <strong> {{ $t("loading") }} </strong>
          </div>
        </template>

        <template #cell(name)="data">
          <router-link
            v-if="
              statusVariant(data.item.status) === 'success' ||
              statusVariant(data.item.status) === 'warning'
            "
            :to="'/admin/nodes/' + data.item.id"
          >
            {{ data.value }}
          </router-link>

          <div v-else>{{ data.value }}</div>
        </template>

        <template #cell(status)="data">
          <b-badge :variant="statusVariant(data.value)">
            {{ statusText(data.value) }}
          </b-badge>
        </template>
      </b-table>

      <b-pagination
        v-if="totalElements > pageSize"
        v-model="currentPage"
        :per-page="pageSize"
        :total-rows="totalElements"
        align="right"
        class="mb-0 mt-3"
        first-text="First"
        last-text="Last"
        next-text="Next"
        prev-text="Prev"
      />

      <b-card-text
        v-if="nodes.length === 0 && !loading"
        class="text-center my-4"
      >
        {{ $t("nodes.emptyTable") }}
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "Nodes",
  data() {
    return {
      nodes: [],
      pageSize: 10,
      totalElements: null,
      currentPage: 1,
      loading: true,
      fields: [
        { key: "name", label: this.$i18n.t("name") },
        { key: "status", label: this.$i18n.t("status") },
      ],
      filter: null,
    };
  },
  mounted() {
    this.getNodes();
  },
  methods: {
    async getNodes() {
      this.loading = true;
      try {
        const {
          data: { content, page },
        } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_VERSION_URL}/agents`,
          {
            params: {
              page: this.currentPage - 1,
              pageSize: this.pageSize,
              search: this.filter,
            },
          }
        );

        this.nodes = content;
        this.totalElements = page.totalElements;
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
      }
    },

    statusVariant(status) {
      switch (status.toUpperCase()) {
        case "UP":
          return "success";
        case "NOT_REACHABLE":
          return "danger";
        case "IMAGE_MISSING":
          return "warning";
        case "UNKNOWN":
        default:
          return "secondary";
      }
    },

    statusText(status) {
      return status.toUpperCase();
    },

    search() {
      this.loading = true;
      this.currentPage = 1;
      this.getNodes();
    },

    clearFilter() {
      this.filter = null;
      this.currentPage = 1;
      this.getNodes();
    },
  },
  watch: {
    currentPage: {
      handler: function () {
        this.getNodes();
      },
    },
  },
};
</script>
